import React, { ReactNode, useContext } from 'react';
import { DiscoveryActorType } from 'generated/graphql';

type DiscoveryContextValues = {
  mediatorHandle?: string;
  mediatorId?: string;
  mediatorType?: DiscoveryActorType;
};

export const DiscoveryContext = React.createContext<DiscoveryContextValues>({});

// DiscoveryContextProvider is used to set the mediator onformation for a discovery to be created
// The mediator is then used in certain mutations like updateReadingState and addToShelf
type ProviderProps = {
  children: ReactNode;
} & DiscoveryContextValues;
export const DiscoveryContextProvider = ({
  children,
  ...discoveryContextValues
}: ProviderProps): JSX.Element => {
  return (
    <DiscoveryContext.Provider value={{ ...discoveryContextValues }}>{children}</DiscoveryContext.Provider>
  );
};

const useDiscoveryContext = (): DiscoveryContextValues => {
  const discoveryContext = useContext(DiscoveryContext);
  return discoveryContext;
};

export default useDiscoveryContext;
