import {
  BookAndProfilesPartsFragment,
  ClubMemberRole,
  ClubStatsPartsFragment,
  ReadingProgressPartsFragment,
} from 'generated/graphql';
import mitt from 'mitt';
import { Maybe } from 'types/generic';

type Events = {
  clubStats: Payload<ClubStatsPartsFragment>;
  readingProgress: Payload<ReadingProgressPartsFragment | null>;
  clubRole: Payload<Maybe<ClubMemberRole>>;
  bookNetwork: Payload<BookAndProfilesPartsFragment | null>;
};

export type Payload<T> = { key: string; value: T };

export const emitter = mitt<Events>();
