import useDebounce from 'hooks/useDebounce';
import { useScreenSize } from 'hooks/useScreenSize';
import { useEffect, useRef, useState } from 'react';
import { ButtonSimple } from 'ui/generic';
import { RemoveIcon, SearchIcon } from 'ui/icons';
import { isTouchScreen } from 'utils/isTouchScreen';
import styles from './DropdownSearchBar.module.scss';

type Props = {
  searchFor: 'books' | 'people' | 'collections';
  handleSubmit: (searchTerm: string) => void;
  handleClear?: () => void;
  preventAutoFocus?: boolean;
};

const DropdownSearchBar = ({
  searchFor,
  handleSubmit,
  handleClear,
  preventAutoFocus,
}: Props): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownSearchRef = useRef<HTMLInputElement>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [hasRecentlySubmitted, setHasRecentlySubmitted] = useState(false);
  const { smScreen } = useScreenSize();

  const placeholder =
    (searchFor === 'books' && 'Search for a title or author') ||
    (searchFor === 'people' && 'Search for people') ||
    (searchFor === 'collections' && 'Search...');

  useEffect(() => {
    if (preventAutoFocus) return;
    const input = dropdownSearchRef.current;
    const focusInput = setTimeout(() => {
      input?.focus();
    }, 800);
    return () => clearTimeout(focusInput);
  }, [dropdownSearchRef]);

  useEffect(() => {
    if (searchTerm === '' && handleClear) handleClear();
  }, [searchTerm]);

  // this bad boy is useful for ensuring the auto-submit doesn't
  // resubmit if you've already submitted manually
  useEffect(() => {
    if (!hasRecentlySubmitted) return;
    const timeout = setTimeout(() => setHasRecentlySubmitted(false), 500);
    return () => clearTimeout(timeout);
  }, [hasRecentlySubmitted]);

  useEffect(() => {
    if (!debouncedSearchTerm || hasRecentlySubmitted) {
      return;
    }
    handleSubmit(debouncedSearchTerm);
    setHasRecentlySubmitted(true);
  }, [debouncedSearchTerm]);

  const clear = () => {
    setSearchTerm('');
    if (!preventAutoFocus) {
      dropdownSearchRef.current?.focus();
    }
    handleClear && handleClear();
  };

  return (
    <form
      className={styles.container}
      onSubmit={(e) => {
        e.preventDefault();
        setHasRecentlySubmitted(true);
        handleSubmit(searchTerm);
        if (isTouchScreen()) {
          (document.activeElement as HTMLElement).blur();
        }
      }}
    >
      {!searchTerm && !smScreen && <SearchIcon />}

      {searchTerm && !smScreen && (
        <div className={styles.clear}>
          <ButtonSimple variant="faded" onClick={clear}>
            <RemoveIcon />
          </ButtonSimple>
        </div>
      )}

      {smScreen && <SearchIcon />}
      <input
        ref={dropdownSearchRef}
        type="search"
        name="query"
        className={styles.input}
        autoComplete="off"
        autoFocus={!preventAutoFocus}
        placeholder={placeholder || 'Search...'}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {searchTerm && smScreen && (
        <div className={styles.mobileClear}>
          <ButtonSimple variant="faded" onClick={clear}>
            <RemoveIcon />
          </ButtonSimple>
        </div>
      )}
    </form>
  );
};

export default DropdownSearchBar;
