import { ReadingState, ReadingStatus } from 'generated/graphql';
import { DroppedIcon, ReadIcon, ReadingIcon, WantToReadIcon } from 'ui/icons';

export type MyReadingStatus = Pick<ReadingState, 'id' | 'bookId' | 'status' | 'createdAt'>;

type Props = {
  readingStatus: ReadingStatus;
};

export const ReadingStatusIcon = ({ readingStatus }: Props): JSX.Element | null => {
  if (!readingStatus) return null;

  if (readingStatus === ReadingStatus.Finished) {
    return <ReadIcon />;
  }
  if (readingStatus === ReadingStatus.IsReading) {
    return <ReadingIcon />;
  }
  if (readingStatus === ReadingStatus.Dropped) {
    return <DroppedIcon />;
  }
  if (readingStatus === ReadingStatus.WantsToRead) {
    return <WantToReadIcon />;
  }
  return null;
};

export default ReadingStatusIcon;
