import { ReadingStatus, ReadingState, BookPartsFragment } from 'generated/graphql';
import { routes } from './routes';

type StatusData = Record<
  ReadingStatus,
  {
    title: string;
    slug: string;
    route: string;
  }
>;

export const statesData: StatusData = {
  [ReadingStatus.WantsToRead]: {
    title: 'Want to read',
    slug: 'wants-to-read',
    route: routes.profileWantToRead,
  },
  [ReadingStatus.IsReading]: {
    title: 'Currently reading',
    slug: 'is-reading',
    route: routes.profileIsReading,
  },
  [ReadingStatus.Dropped]: {
    title: 'Did not finish',
    slug: 'dropped',
    route: routes.profileDropped,
  },
  [ReadingStatus.Finished]: {
    title: 'Finished',
    slug: 'has-read',
    route: routes.profileHasRead,
  },
  [ReadingStatus.None]: {
    title: '',
    slug: '',
    route: '',
  },
};

export const statesDataShort: StatusData = {
  [ReadingStatus.WantsToRead]: {
    title: 'To read',
    slug: 'wants-to-read',
    route: routes.profileWantToRead,
  },
  [ReadingStatus.IsReading]: {
    title: 'Reading',
    slug: 'is-reading',
    route: routes.profileIsReading,
  },
  [ReadingStatus.Dropped]: {
    title: 'Did not finish',
    slug: 'dropped',
    route: routes.profileDropped,
  },
  [ReadingStatus.Finished]: {
    title: 'Finished',
    slug: 'has-read',
    route: routes.profileHasRead,
  },
  [ReadingStatus.None]: {
    title: '',
    slug: '',
    route: '',
  },
};

type MinReadingState = Pick<ReadingState, 'id' | 'status'> & {
  book: BookPartsFragment;
};

type ReturnType = {
  WANTS_TO_READ: BookPartsFragment[];
  IS_READING: BookPartsFragment[];
  FINISHED: BookPartsFragment[];
  DROPPED: BookPartsFragment[];
};

export const groupReadingStatesByStatus = (readingStates: MinReadingState[]): ReturnType => {
  const output = {
    [ReadingStatus.WantsToRead]: [] as BookPartsFragment[],
    [ReadingStatus.IsReading]: [] as BookPartsFragment[],
    [ReadingStatus.Finished]: [] as BookPartsFragment[],
    [ReadingStatus.Dropped]: [] as BookPartsFragment[],
  };
  readingStates.forEach((readingState) => {
    if (readingState.status === ReadingStatus.None) return;
    output[readingState.status].push(readingState.book);
  });

  return output;
};

export const getCurrentlyReading = (readingStates: MinReadingState[]): BookPartsFragment[] => {
  const grouped = groupReadingStatesByStatus(readingStates);

  return grouped[ReadingStatus.IsReading];
};
