export function scrollElementIntoView(target: string | React.RefObject<HTMLElement>, offsetPx = 100): void {
  const element = typeof target === 'string' ? document.getElementById(target) : target.current;
  if (!element) return;

  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offsetPx;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
}
