import { PureQueryOptions } from '@apollo/client';
import { dayjs } from 'date-utils';
import {
  BookCountsByProfileIdDocument,
  BookPartsFragment,
  BooksByReadingStateAndProfileDocument,
  DiscoveriesByBookDocument,
  DiscoveryActorType,
  GetReadDatesDocument,
  MyBookCountsDocument,
  MyBooksByReadingStateDocument,
  MyBooksDocument,
  MyGoalParticipationsDocument,
  MyReadingStatesDocument,
  ReadingStateByWorkDocument,
  ReadingStateWorkPartsFragmentDoc,
  ReadingStatus,
  UpdateReadingStateMutationVariables,
  useUpdateReadingStateMutation,
} from 'generated/graphql';
import useMixpanel, { readableReadingStatus } from 'hooks/useMixpanel';
import { useMemo, useState } from 'react';
import { MyReadingState } from 'types/readingState';
import { useBookModalContext } from './modals/useBookModal';
import { useAuthContext } from './useAuth';
import useDiscoveryContext from './useDiscoveryContext';

type ReturnValue = {
  loading: boolean;
  // todo - rething the way this is done
  updateReadingState: (
    status: ReadingStatus,
    book: BookPartsFragment,
    oldReadingState?: MyReadingState
  ) => Promise<void>;
};

const useUpdateReadingstate = (): ReturnValue => {
  const { profile } = useAuthContext();
  const today = useMemo(() => dayjs().toISOString(), []);
  const [loading, setLoading] = useState(false);
  const mixpanel = useMixpanel();
  const discoveryContext = useDiscoveryContext();
  const { startBookFinishedWizard } = useBookModalContext();

  const [updateReadingStateMutation] = useUpdateReadingStateMutation();

  const updateReadingState = async (
    status: ReadingStatus,
    book: BookPartsFragment,
    oldReadingState?: MyReadingState
  ) => {
    if (!profile) return;
    setLoading(true);

    const variables: UpdateReadingStateMutationVariables = {
      bookId: oldReadingState?.bookId || book.id,
      readingStatus: status,
    };

    // Discovery creation:
    if (discoveryContext.mediatorHandle) {
      variables.mediatorHandle = discoveryContext.mediatorHandle;
      variables.mediatorType = DiscoveryActorType.Profile;
    }
    await updateReadingStateMutation({
      variables,
      optimisticResponse: {
        updateReadingState: {
          book: book,
          profileId: profile.id,
          bookId: oldReadingState?.bookId || book.id,
          status: status,
          id: oldReadingState ? oldReadingState.id : 'tempId',
          __typename: 'ReadingState',
          createdAt: oldReadingState ? oldReadingState.createdAt : new Date().toJSON(),
        },
      },
      update: (cache, { data }) => {
        if (!data) return null;

        const newState = {
          ...data.updateReadingState,
          workId: oldReadingState?.workId || null,
          // __typename is important, because it's used by the cache to create the ref
          __typename: 'ReadingStateWork',
        };

        cache.modify({
          fields: {
            myReadingStatesWithWork(existingRefs = []) {
              const newRef = cache.writeFragment({
                data: newState,
                fragment: ReadingStateWorkPartsFragmentDoc,
              });
              return [newRef, ...existingRefs];
            },
          },
        });
      },

      refetchQueries: () => {
        const queries: Array<PureQueryOptions> = [
          { query: MyReadingStatesDocument },
          {
            query: MyBooksByReadingStateDocument,
            variables: { limit: 20, offset: 0, readingStatus: status },
          },
          { query: BookCountsByProfileIdDocument, variables: { profileId: profile.id } },
          { query: MyBookCountsDocument },
          { query: MyBooksDocument, variables: { offset: 0, limit: 20 } },
          {
            query: GetReadDatesDocument,
            variables: { bookId: book.id, profileId: profile.id },
          },
          {
            query: ReadingStateByWorkDocument,
            variables: { bookId: book.id, profileId: profile.id },
          },
        ];
        if (status === 'FINISHED') {
          queries.push({
            query: MyGoalParticipationsDocument,
            variables: { limit: 20, offset: 0, earliestEndDate: today },
          });
        }
        if (status !== 'NONE') {
          queries.push(
            {
              query: BooksByReadingStateAndProfileDocument,
              variables: { limit: 20, offset: 0, readingStatus: status, profileId: profile.id },
            },
            {
              query: MyBooksByReadingStateDocument,
              variables: { limit: 20, offset: 0, readingStatus: status },
            }
          );
        }
        if (oldReadingState) {
          queries.push(
            {
              query: BooksByReadingStateAndProfileDocument,
              variables: {
                limit: 20,
                offset: 0,
                readingStatus: oldReadingState.status,
                profileId: profile.id,
              },
            },
            {
              query: MyBooksByReadingStateDocument,
              variables: { limit: 20, offset: 0, readingStatus: oldReadingState.status },
            }
          );
        }

        if (discoveryContext.mediatorId) {
          queries.push({
            query: DiscoveriesByBookDocument,
            variables: {
              actorId: discoveryContext.mediatorId,
              bookId: book.id,
            },
          });
        }

        return queries;
      },

      onCompleted: (data) => {
        if (!data?.updateReadingState) return;

        const oldStatus = oldReadingState?.status;
        const newStatus = data.updateReadingState.status;
        if (newStatus === 'FINISHED' && data && oldStatus === ReadingStatus.IsReading) {
          startBookFinishedWizard(data.updateReadingState.book.id);
        }
        mixpanel.trackEvent('reading_state_updated', {
          status: readableReadingStatus[newStatus],
          isbn13: data.updateReadingState.book.isbn13,
          title: data.updateReadingState.book.title,
          readingStateId: data.updateReadingState.id,
        });
      },
    });
    setLoading(false);
  };

  return { loading, updateReadingState };
};

export default useUpdateReadingstate;
