import DataLoader from 'dataloader';
import {
  ShelfBookProfile,
  ShelfBookProfileByWorkDocument,
  ShelfBookProfileByWorkQuery,
  ShelfBookProfileByWorkQueryVariables,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import getApolloClient from 'utils/getApolloClient';

type Pair = {
  bookId: string;
  profileId: string;
};

export const shelfLoader = new DataLoader(
  async (keys: readonly Pair[]): Promise<ShelfBookProfile[][]> => {
    const client = getApolloClient();

    const { data } = await client.query<ShelfBookProfileByWorkQuery, ShelfBookProfileByWorkQueryVariables>({
      query: ShelfBookProfileByWorkDocument,
      variables: { pairs: [...keys] },
      fetchPolicy: 'no-cache',
    });

    return data.shelfBookProfileByWork;
  },
  {
    batch: true,
    cache: true,
    cacheKeyFn: (key: Pair): string => `shelf:${key.bookId}:${key.profileId}`,
  }
);

export function useShelfLoader({ profileId, bookId }: Pair) {
  const [data, setData] = useState<ShelfBookProfile[]>([]);

  useEffect(() => {
    if (!profileId || !bookId) return;

    shelfLoader.load({ profileId, bookId }).then((s) => {
      setData(s);
    });
  }, [profileId, bookId]);

  return {
    data,
    setData,
    refetch: async () => {
      shelfLoader.clear({ profileId, bookId });
      const data = await shelfLoader.load({ profileId, bookId });
      setData(data);
    },
  };
}
