import DataLoader from 'dataloader';
import {
  Maybe,
  ReadingProgressesDocument,
  ReadingProgressesQuery,
  ReadingProgressesQueryVariables,
  ReadingProgressPartsFragment,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import getApolloClient from 'utils/getApolloClient';
import { notEmpty } from 'utils/typeGuards';
import { emitter, Payload } from './events';

export const readingProgressLoader = new DataLoader(
  async (keys: readonly string[]): Promise<Maybe<ReadingProgressPartsFragment>[]> => {
    const client = getApolloClient();

    const { data } = await client.query<ReadingProgressesQuery, ReadingProgressesQueryVariables>({
      query: ReadingProgressesDocument,
      variables: { bookIds: [...keys], active: true },
      fetchPolicy: 'no-cache',
    });

    return data.readingProgresses;
  },
  { batch: true, cache: true }
);

export function useReadingProgressLoader(bookId?: string | null) {
  const [data, setData] = useState<ReadingProgressPartsFragment | null>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    load();
    function applyNewData({ key, value }: Payload<ReadingProgressPartsFragment | null>) {
      if (key === bookId) setData(value);
    }
    emitter.on('readingProgress', applyNewData);
    return () => emitter.off('readingProgress', applyNewData);
  }, [bookId]);

  async function load() {
    if (!bookId) return;
    setLoading(true);
    const newVal = await readingProgressLoader.load(bookId);
    emitter.emit('readingProgress', { key: bookId, value: newVal });
    setLoading(false);
  }

  async function refetch() {
    if (!bookId) return;
    readingProgressLoader.clear(bookId);
    await load();
  }

  return { data, setData, refetch, loading };
}

export function useHasAnyProgress(bookIds: string[]) {
  const [hasProgress, setHasProgress] = useState(false);

  useEffect(() => {
    function reload() {
      setTimeout(async () => {
        const progresses = await readingProgressLoader.loadMany(bookIds);
        const hasProgress = progresses
          .filter(notEmpty)
          .map((p) => {
            if ('progress' in p && p.progress) return p.progress;
          })
          .filter(notEmpty)
          .some((p) => p > 0);
        setHasProgress(hasProgress);
      }, 100);
    }

    reload();

    emitter.on('readingProgress', reload);
    return () => emitter.off('readingProgress', reload);
  }, [bookIds]);

  return hasProgress;
}
