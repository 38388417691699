import RadioButton from 'components/basics/RadioButton';
import { useReadingProgressLoader } from 'dataloaders/readingProgressLoader';
import { BookPartsFragment, ReadingStatus } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import { useMyReadingStateByBook } from 'hooks/useReadingStates';
import useUpdateReadingstate from 'hooks/useUpdateReadingstate';
import { useMemo } from 'react';
import { DroppedIcon, ReadIcon, ReadingIcon, WantToReadIcon } from 'ui/icons';
import { ActionItem, ActionItemClickable } from 'ui/specific/actionList';

interface BookShelfActionItemProps {
  book: BookPartsFragment;
}

export const ReadingStateActionItems = ({ book }: BookShelfActionItemProps): JSX.Element | null => {
  const { profile } = useAuthContext();
  const { updateReadingState } = useUpdateReadingstate();
  const { refetch: refetchProgressDataLoader } = useReadingProgressLoader(book.id);
  const readingState = useMyReadingStateByBook({ bookId: book.id, workId: book.workId });

  const items: ActionItemClickable<ReadingStatus>[] = useMemo(() => {
    return [
      {
        title: 'Want to read',
        subtitle: <RadioButton isChecked={readingState?.status === ReadingStatus.WantsToRead} />,
        icon: <WantToReadIcon />,
        value: ReadingStatus.WantsToRead,
        onClick: (val) => handleClick(val),
      },
      {
        title: 'Reading',
        subtitle: <RadioButton isChecked={readingState?.status === ReadingStatus.IsReading} />,
        icon: <ReadingIcon />,
        value: ReadingStatus.IsReading,
        onClick: (val) => handleClick(val),
      },
      {
        title: 'Did not finish',
        subtitle: <RadioButton isChecked={readingState?.status === ReadingStatus.Dropped} />,
        icon: <DroppedIcon />,
        value: ReadingStatus.Dropped,
        onClick: (val) => handleClick(val),
      },
      {
        title: 'Finished',
        subtitle: <RadioButton isChecked={readingState?.status === ReadingStatus.Finished} />,
        icon: <ReadIcon />,
        value: ReadingStatus.Finished,
        onClick: (val) => handleClick(val),
      },
    ];
  }, [readingState]);

  if (!profile) return null;

  const handleClick = (status: ReadingStatus) => {
    if (readingState?.status === status) {
      updateReadingState(ReadingStatus.None, book, readingState);
    } else {
      updateReadingState(status, book, readingState).then(() => {
        if (status === ReadingStatus.Finished) refetchProgressDataLoader();
      });
    }
  };

  return (
    <>
      {items.map((item, index) => (
        <ActionItem key={index} item={item} />
      ))}
    </>
  );
};
