import classNames from 'classnames';
import styles from './RadioButton.module.scss';

type RadioButtonProps = {
  isChecked: boolean;
};
export const RadioButton = ({ isChecked }: RadioButtonProps): JSX.Element => {
  return (
    <span
      className={classNames(styles.radio, {
        [styles.checked]: isChecked,
      })}
    />
  );
};

export default RadioButton;
